.blur-mask {
  position: absolute;
  opacity: 0.4;
  left: 0;
  right: 0;
  z-index: 9999;
  background: #000;
  z-index: 1;
  bottom: 0;
  top: 0;
}

.titan-caption {
  z-index: 1;
}

.module {
  padding: 4em 2em;
}

.testimonials-slider {
  z-index: 1;
}

.flex-viewport {
  // max-height: 0;
}

#intro .module-subtitle {
  margin-bottom: 10px;
}

#milestone .module-title, #services .module-title {
  margin-bottom: 40px;
}

#services .module-subtitle {
  margin-bottom: 30px;
}

#blog_index, #blog_show, #blog_category  {
  .comments {
    margin: 20px 0 0 0;
  }
  .post {
    margin: 0;
  }
  .widget {
    font-size: 12px;
    font-weight: 400;
    text-align: justify;
    letter-spacing: 0px;
    p {
      margin: 1em 0;
    }   
  }
  .module, .module-small {
    padding: 2em 0em;
  }
  img {
    border-radius: 10px;
  }
  .post-title {
    margin: 1em 0;
  }
  .post-entry {
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
  }
  .post-meta a, .comment-author a {
    text-transform: initial;
  }
  .breadcrumb {
    padding: 8px 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: #f1f1f1;
    border-radius: 10px;  
    a {
      color: #565656;
    }
    li {
      float: left;
    }
    &:after {
      content: '';
      clear: both;
      display: block;
    }
  }
}

nav .fb-like {
  margin: 13px;
  display: block !important;
}

.fb-save {
  margin: 1em 0;
  display: block !important;
}

/* 
Make the Facebook Like box responsive (fluid width)
https://developers.facebook.com/docs/reference/plugins/like-box/ 
*/

/* 
This element holds injected scripts inside iframes that in 
some cases may stretch layouts. So, we're just hiding it. 
*/

#fb-root {
    // display: none;
}

/* To fill the container and nothing else */
#blog_show .fb_iframe_widget, #blog_show .fb_iframe_widget span, #blog_show .fb_iframe_widget span iframe[style] {
    width: 100% !important;
}

  .features-item {
    min-height: 15em;
  }